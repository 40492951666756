import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIROUTES } from '../../constants';
import { BaseService } from '../base/base.service';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class GlobalPrayerService {

  constructor(
    private baseService: BaseService
  ) { }

  //get countries
  public startEndPrayer(action: 'start'|'end' = 'start', date: string = format(new Date, 'Y/MM/dd HH:mm:ss')){
    return this.baseService.post(environment.baseUrl + APIROUTES.GLOBALPRAYER.PRAYER.PRAYACTION + action, {date}).pipe(catchError(this.errorHandler));
  }

  public getPrayerTime(){
    return this.baseService.get(environment.baseUrl + APIROUTES.GLOBALPRAYER.PRAYER.PRAYERTIME).pipe(catchError(this.errorHandler));
  }

  public getCoveredPrayerProgress(){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.USER.COVERED).pipe(catchError(this.errorHandler));
  }
  
  getPrayerCards(is_prayforall : number) {
    return this.baseService.get(environment.baseUrl + APIROUTES.GLOBALPRAYER.CARDS.LIST+'?is_prayforall='+is_prayforall).pipe(catchError(this.errorHandler));
  }
  
  getPrayerVideos(is_prayforall : number) {
    return this.baseService.get(environment.baseUrl + APIROUTES.GLOBALPRAYER.VIDEOS.LIST+'?is_prayforall='+is_prayforall).pipe(catchError(this.errorHandler));
  }
  
  getPrayerBoards() {
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERBOARD.LISTANY).pipe(catchError(this.errorHandler));
  }
  
  getPrayerJournals(is_prayforall : number) {
    return this.baseService.get(environment.baseUrl + APIROUTES.GLOBALPRAYER.JOURNAL.LIST+'?is_prayforall='+is_prayforall).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}
