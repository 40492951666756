import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, forkJoin, firstValueFrom, noop, delay, of, switchMap, lastValueFrom, BehaviorSubject } from 'rxjs';
import { flatten, unflatten } from 'flat';
import { chunk } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslateService {
  baseUrl: string = 'https://translation.googleapis.com/language/translate/v2';
  apiParams = {params: {key: environment.translateAPIKey}};

  private _translationProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private request: HttpClient
  ) {}

  public get getTranslationProgress(): Observable<number> {
    return this._translationProgress.asObservable();
  }

  public setTranslationProgress(progress: number) {
    this._translationProgress.next(progress);
  }

  translateBatchText(q: string[], target: string, source: string = 'en', format: string = 'text'): Observable<string[]> {
    return this.request.post<{[x: string]: string}|any>(
      this.baseUrl, {
      q,
      target,
      source,
      format
    }, this.apiParams).pipe(
      map((response) => response.data.translations.map((translation: {translatedText: string}) => translation.translatedText))
    );
  }

  translateAText(q: string, target: string, source: string = 'en') {
    return this.translateText(q, target, source);
  }

  async oneTimeTranslateObject(jsonObject: {[x: string]: string}|any, targetLanguage: string, source: string = 'en'): Promise<{[x: string]: string}|undefined> {
    let flattenObject = flatten(jsonObject) as Record<string, string>;
    let objectEntries = Object.entries(flattenObject);
    let chunkedValues = chunk(objectEntries, 125);

    for (let index = 0; index < chunkedValues.length; index++) {
      let arrayOfTexts = chunkedValues[index];

      let translated = await firstValueFrom(this.translateBatchText(arrayOfTexts.map((texts) => texts[1]), targetLanguage, source)).catch(noop);

      if (translated) {
        arrayOfTexts = arrayOfTexts.map((texts, index) => {
          texts[1] = translated ? translated[index] : texts[1];
          return texts;
        });
      }

      chunkedValues[index] = arrayOfTexts;
      this.setTranslationProgress((index + 1) / chunkedValues.length);
    }
    
    const obj = Object.fromEntries(chunkedValues.flat());
    // Convert the Observable to a Promise
    return unflatten(obj);
  }

  translateObject(jsonObject: {[x: string]: string}|any, targetLanguage: string, source: string = 'en'): Promise<{[x: string]: string}|undefined> {
    console.log({flatten: flatten(jsonObject)});
    // Recursively translate values in the JSON object
    const translatedObject$ = this.translateValuesRecursively(jsonObject, targetLanguage, source);

    // Convert the Observable to a Promise
    return translatedObject$.toPromise();
  }

  private translateValuesRecursively(
    obj: {[x: string]: string},
    targetLanguage: string,
    source: string
  ): Observable<{[x: string]: string}> {
    const keys = Object.keys(obj);

    // Translate values without changing keys
    const translatedValues$ = keys.map((key) => {
      const value = obj[key];

      if (typeof value === 'object') {
        // Recursively translate nested objects
        return this.translateValuesRecursively(value, targetLanguage, source).pipe(
          map((translatedNestedObject) => ({ [key]: translatedNestedObject }))
        );
      } else {
        // Translate the value
        return this.translateText(value, targetLanguage, source).pipe(map((translatedValue) => ({ [key]: translatedValue })));
      }
    });

    // Combine the translated values into a single object
    return forkJoin(translatedValues$).pipe(
      map((translatedValuesArray: any) => translatedValuesArray.reduce(
          (acc: any, curr: any) => {
            return { ...acc, ...curr };
        }), {}
      )
    );
  }

  private translateText(text: string, targetLanguage: string, source = 'en'): Observable<string> {
    const body = {
      q: text,
      target: targetLanguage,
      format: 'text',
      source, // Assuming the source language is English
    };

    return this.request.post<any>(this.baseUrl, body, this.apiParams).pipe(
      map((response) => response.data.translations[0].translatedText)
    );
  }

  detectLanguage(text: string|string[]): Promise<string[]> {
    let q = Array.isArray(text) ? text : [text];
    return firstValueFrom(this.request.post<any>(this.baseUrl + '/detect', {q}, this.apiParams).pipe(
      map((response) => {
        let detections = response.data.detections;
        return (detections as any[]).map(
          (value: {
            confidence: number
            isReliable: boolean,
            language: string
          }[], index) => {
            if (value.length > 1) {
              const highestConfidence = Math.max(...value.map(obj => obj.confidence));
              return value.filter(v => v.confidence === highestConfidence)[0].language;
            } else {
              return value[0].language;
            }
        });
      })
    ));
  }
}
