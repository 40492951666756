import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { TranslateLoader, TranslateModule, TranslateParser, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LaravelLikeParserService, TokerInterceptorService } from './shared/services';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MediaTypePipe } from './shared/pipes/media-type.pipe';
import { HasPermissionPipe } from './shared/pipes/has-permission.pipe';
import { HasRolePipe } from './shared/pipes/has-role.pipe';

import { AvatarModule, AvatarSource } from 'ngx-avatars';
import { APIROUTES } from './shared/constants';
import { PasswordStrengthPipe } from './shared/pipes/password-strength.pipe';
import { HasRolesPipe } from './shared/pipes/has-roles.pipe';
import { FormsModule } from '@angular/forms';
import { CanBeTranslatedPipe } from './shared/pipes/can-be-translated/can-be-translated.pipe';
import { NgApexchartsModule } from 'ng-apexcharts';

export function createTranslateLoader(http: HttpClient) {
  // return new TranslateHttpLoader(http, environment.languageFilesPath, '.json');
  return new TranslateHttpLoader(http, environment.baseUrl + APIROUTES.LANGUAGE.LOAD, '');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      toastDuration: 4000,
      mode: 'ios'
    }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    // Translate Module
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useClass: LaravelLikeParserService, // Use the custom parser
      }
    }),
    NgCircleProgressModule.forRoot({
      // set defaults here
      toFixed: 2,
      backgroundStrokeWidth: 0,
      backgroundPadding: -7,
      radius: 113,
      space: -25,
      maxPercent: 100,
      unitsFontSize: "20",
      outerStrokeWidth: 25,
      outerStrokeColor: "#008a6e",
      innerStrokeColor: "#dedede",
      innerStrokeWidth: 25,
      titleFontSize: "60",
      titleFontWeight: "800",
      subtitleFontSize: "21",
      animationDuration: 300,
      responsive: true
    }),
    
    // Avatars
    AvatarModule.forRoot({
      sourcePriorityOrder: [AvatarSource.CUSTOM, AvatarSource.VALUE, AvatarSource.INITIALS]
    }),

    // chart
    NgApexchartsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokerInterceptorService,
      multi: true
    },
    TranslatePipe,
    MediaTypePipe,
    HasPermissionPipe,
    HasRolePipe,
    HasRolesPipe,
    PasswordStrengthPipe,
    CanBeTranslatedPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
