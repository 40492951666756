import Echo from "laravel-echo";
import { environment } from "src/environments/environment";
import Pusher from 'pusher-js';

export function establishWebSocketConnection() {
    const pusherKey = 'local';
    const webSocketHost = environment.webSocketHost;
    var pusherData: any = {
      cluster: 'mt1',
      wsHost: webSocketHost,
      disableStats: false,
      forceTLS: true,
      encrypted: true,
      scheme: 'https',
      enabledTransports: ['ws', 'wss'],
    };
    if (webSocketHost === '127.0.0.1') {
      pusherData.wsPort = 6001;
      pusherData.wssPort = 6001;
      pusherData.forceTLS = false;
      pusherData.encrypted = false;
      pusherData.scheme = 'http';
    }
    const pusher = new Pusher(pusherKey, pusherData);
  
    return new Echo({
      broadcaster: 'pusher',
      client: pusher,
      forceTLS: false,
    });
  }