import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../auth/storage/storage.service';
import { environment } from 'src/environments/environment';
import Pusher from 'pusher-js/types/src/core/pusher';
import { establishWebSocketConnection } from '../../utils/websocket-connection';

@Injectable({
  providedIn: 'root'
})
export class NotificationWebsocketService {
  echo!: any;
  private _incomingFriendShipNotification = new BehaviorSubject<any>([]);
  private _incomingUserNotification = new BehaviorSubject<any>([]);
  private _incomingFriendshipRequest = new BehaviorSubject<any>([]); 
  private _incomingAcceptFriendRequest = new BehaviorSubject<any>([]); 


  constructor(
    private authStorageService: StorageService
  ) {

    this.echo = establishWebSocketConnection();

   }

  webSocketListeners() {
    this.listenToIncomingNotificationFriendShip();
    this.listenToIncomingUserNotification();
    this.listenToIncomingFriendshipRequest();
    this.listenToIncomingAcceptFriendRequest();
  }
  

  newIncomingFriendShipNotification(){
    return this._incomingFriendShipNotification.asObservable();
  }

  newIncomingUserNotification()
  {
    return this._incomingUserNotification.asObservable();
  }

  newIncomingFriendshipRequest()
  {
    return this._incomingFriendshipRequest.asObservable();
  }

  newIncomingAcceptFriendRequest()
  {
    return this._incomingAcceptFriendRequest.asObservable();
  }

  listenToIncomingNotificationFriendShip(){
    let userID: number = this.authStorageService.user?.id as number;
    this.echo.channel('friendship-notification-channel.'+userID).listen('.friendship-notification', (e: any) => {
      this._incomingFriendShipNotification.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  listenToIncomingUserNotification(){
    let userID: number = this.authStorageService.user?.id as number;
    this.echo.channel('prayforall-user-notification-channel.'+userID).listen('.prayforall-user-notification', (e: any) => {
      this._incomingUserNotification.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  // friendship request 
  listenToIncomingFriendshipRequest()
  {
    let userID: number = this.authStorageService.user?.id as number;
    this.echo.channel('friend-request-notification-channel.'+userID).listen('.friend-request-notification', (e: any) => {
      this._incomingFriendshipRequest.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  // accept friend request
  listenToIncomingAcceptFriendRequest()
  {
    let userID: number = this.authStorageService.user?.id as number;
    this.echo.channel('accept-friend-request-notification-channel.'+userID).listen('.accept-friend-request-notification', (e: any) => {
      this._incomingAcceptFriendRequest.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }


}
