import { Injectable } from '@angular/core';
import { AlertController, AlertOptions, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    public alertController: AlertController,
    private translate: TranslateService
  ) { }

  async alert(message: string, subHeader?: string, header?: string){
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async confirm(
    message: string = this.translate.instant('common.continue_message'),
    okText: string = this.translate.instant('common.confirm'),
    cancelText: string = this.translate.instant('common.cancel'),
    header: string = this.translate.instant('common.please_confirm')
  ){
    const confirm = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [{
          text: cancelText,
          role: 'cancel'
        },
        {
          text: okText,
          role: 'confirm'
      }]
    });
    return confirm;
  }

  async dialog(options: AlertOptions){
    const dialog = await this.alertController.create(options);
    return dialog;
  }

}
