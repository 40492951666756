import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    private loadingController: LoadingController,
    private translate: TranslateService
  ) { }

  async show(messageKey: string = 'common.please_wait', backdropDismiss: boolean = false, messageParams: any = {} ) {
    const message = this.translate.instant(messageKey, messageParams);
    await this.dismiss();
    const loading = await this.loadingController.create({
      message,
      backdropDismiss
    });
    await loading.present();
  }

  async dismiss() {
    const loading = await this.loadingController.getTop();
    if (loading) {
      await loading.dismiss();
    }
  }
}
