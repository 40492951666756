// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  preLaunch: false,
  hostname: 'https://worldprays.globaledge-software.com/',

  /* *** Staging Platform *** */
  baseUrl: 'https://worldprays-api.globaledge-software.com/api/',
  webSocketHost: 'worldprays-api.globaledge-software.com',

  /* *** Local Development Platform *** */
  // baseUrl: 'http://localhost:8000/api/',
  // webSocketHost: '127.0.0.1',
  languageFilesPath: './assets/i18n/',
  translateAPIKey: 'AIzaSyAh-PnZzx7NnjtUF0Z7BrKlISU49L-t4w0',
  
  firebase: {
    apiKey: "AIzaSyBgeS_NOsz51B6Ve57WpS2DNrCexXnjbvY",
    authDomain: "world-prays.firebaseapp.com",
    projectId: "world-prays",
    storageBucket: "world-prays.appspot.com",
    messagingSenderId: "608055171780",
    appId: "1:608055171780:web:e0c0b27aabd605522c8791",
    measurementId: "G-SC0YWVYRYT",
    vapidKey: 'BLIgJaWW0w0OdRrXBw9rtsoLikMYVFMo3CbZmATadN0PLuymLQ73yxa9fG670HadYpvTvlZBAXCcX_rYt0md2Hs'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
