import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base/base.service';
import { APIROUTES } from 'src/app/shared/constants';


@Injectable({
  providedIn: 'root'
})
export class FetchService {

  constructor(
    private baseService: BaseService
  ) { }

  //get countries
  public getCountries(offset: number = 0, countryName?: string){
    return this.baseService.get(environment.baseUrl + APIROUTES.LOCATION.COUNTRIES + offset + (countryName ? '?countryName=' + countryName : '')).pipe(catchError(this.errorHandler));
  }

  public getCountriesByName(name: string = ''){
    return this.baseService.get(environment.baseUrl + APIROUTES.LOCATION.COUNTRIES + "?countryName="+name).pipe(catchError(this.errorHandler));
  }

  public getCountriesById(id: string = ''){
    return this.baseService.get(environment.baseUrl + APIROUTES.LOCATION.COUNTRIES + "?countryId="+id.toString()).pipe(catchError(this.errorHandler));
  }

  public getCountriesByCode(code: string = ''){
    return this.baseService.get(environment.baseUrl + APIROUTES.LOCATION.COUNTRIES + "?countryCode="+code).pipe(catchError(this.errorHandler));
  }

  // Register firebase cloud messaging token to the database
  public registerFCMToken(userId: number, token: string){
    return this.baseService.post(environment.baseUrl + APIROUTES.PUSHNOTIFICATION.REGISTER + userId, {token}).pipe(catchError(this.errorHandler));
  }

  //get cities
  public getCities(countryCode: string, offset: number = 0, cityName?: string){
    return this.baseService.get(environment.baseUrl + APIROUTES.LOCATION.CITIES + countryCode + '/' + offset + (cityName ? '?cityName=' + cityName : '')).pipe(catchError(this.errorHandler));
  }
  public getCitiesByName(countryCode: string, name: string = ''){
    return this.baseService.get(environment.baseUrl + APIROUTES.LOCATION.CITIES + countryCode + "?cityName=" + name).pipe(catchError(this.errorHandler));
  }
  public getCitiesById(countryCode: string, id: string = ''){
    return this.baseService.get(environment.baseUrl + APIROUTES.LOCATION.CITIES + countryCode + "?cityId=" + id.toString()).pipe(catchError(this.errorHandler));
  }

  public getLanguages(){ // prefered language
    return this.baseService.get(environment.baseUrl + APIROUTES.MASTERDATA.LANGUAGES).pipe(catchError(this.errorHandler));
  }

  public getPublishedLanguages(){
    return this.baseService.get(environment.baseUrl + APIROUTES.LANGUAGE.LOADALL).pipe(catchError(this.errorHandler));
  }

  public getUserTypes(){
    return this.baseService.get(environment.baseUrl + APIROUTES.MASTERDATA.USERS).pipe(catchError(this.errorHandler))
  }

  public getUser(user: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.PROFILE.GET(user)).pipe(catchError(this.errorHandler))
  }
  public getOrganizationTypes(){
    return this.baseService.get(environment.baseUrl + APIROUTES.MASTERDATA.ORGANIZATIONS).pipe(catchError(this.errorHandler))
  }

  //get PMDs
  public listPMDs(){
    return this.baseService.get(environment.baseUrl + APIROUTES.PMD.LIST).pipe(catchError(this.errorHandler))
  }
  public listPMDsByName(name: string){
    return this.baseService.get(environment.baseUrl + APIROUTES.PMD.LIST + '?name=' + name).pipe(catchError(this.errorHandler))
  }
  public getPMD(id: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.PMD.PROFILE.GET(id)).pipe(catchError(this.errorHandler));
  }
  public getPMDByIdWithoutAuth(id: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.PMD.PROFILE.GETWITHOUTAUTH(id)).pipe(catchError(this.errorHandler));
  }

  //get Organizations
  public listOrganizations(){
    return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.LIST).pipe(catchError(this.errorHandler))
  }
  public listOrganizationsByName(name: string){
    return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.LIST + '?name=' + name).pipe(catchError(this.errorHandler))
  }
  public listOrganizationsByCountryCode(code: string){
    return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.LIST + '?country_code=' + code).pipe(catchError(this.errorHandler))
  }
  public listOrganizationsByCityId(id: string){
    return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.LIST + '?city_id=' + id.toString()).pipe(catchError(this.errorHandler))
  }

  public getOrganizationById(id: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.PROFILE.GET(id)).pipe(catchError(this.errorHandler));

  }
  public getOrganizationByIdWithoutAuth(id: number){
    // return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.PROFILE.GETWITHOUTAUTH(id)).pipe(catchError(this.errorHandler));
    return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.PROFILE.GET(id)).pipe(catchError(this.errorHandler));
  }

  //get percentage hour covered by an organization
  public getOrganizationPrayerHours(body:any){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.ORGANIZATION.COVERED, body).pipe(catchError(this.errorHandler));
  }

  //get organization success prayer
  public getOrganizationSuccessPrayer(body:any){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.ORGANIZATION.SUCCESSPRAYER, body).pipe(catchError(this.errorHandler));
  }

  //get organization prayer dates
  public getOrganizationPrayerDatePerMonth(organizationId: number, body:any){
    // return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.ORGANIZATION.GETPRAYERBYMONTH(organizationId), body).pipe(catchError(this.errorHandler));
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.ORGANIZATION.GETORGANIZATIONPRAYERBYMONTH(organizationId), body).pipe(catchError(this.errorHandler));
  }

  //get city report data
  public getCityReportData(body: any){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.CITY.REPORT, body).pipe(catchError(this.errorHandler));
  }

  public inviteMembers(data: {email: string, link: string, qrcode: string}, request: any) {
    return this.baseService.post(environment.baseUrl + APIROUTES.INVITE.MEMBER + request, data).pipe(catchError(this.errorHandler));
  }
  //get city report data
  public getPMDReportData(pmd: number, month: string, year: number = new Date().getFullYear()){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.PMD.REPORT + pmd, {month, year}).pipe(catchError(this.errorHandler));
  }

  // get location pmd data
  public getPMDLocationData(pmd: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.PMD.REPORT_LOCATION(pmd)).pipe(catchError(this.errorHandler));
  }

  // get pmd report
  public getPMDReportDataFilter(pmd: number, body: any){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERREPORT.PMD.REPORT_DATA(pmd), body).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}
