import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { catchError, of } from 'rxjs';
import { APIROUTES } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';
import { BaseService } from '../../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class PrayerJournalService {

  constructor(
    private baseService: BaseService
  ) { }
  
  getPrayerJournals() {
    return this.baseService.get(environment.baseUrl + APIROUTES.GLOBALPRAYER.JOURNAL.LIST).pipe(catchError(this.errorHandler));
  }

  createPrayerJournal(content: string,is_prayforall: number) {
    return this.baseService.post(environment.baseUrl + APIROUTES.GLOBALPRAYER.JOURNAL.CREATE,  {content,is_prayforall}).pipe(catchError(this.errorHandler));
  }

  updatePrayerJournal(journal: number, content: string, is_prayforall : number) {
    return this.baseService.put(environment.baseUrl + APIROUTES.GLOBALPRAYER.JOURNAL.UPDATE + journal,  {content,is_prayforall}).pipe(catchError(this.errorHandler));
  }

  removePrayerJournal(journal: number) {
    return this.baseService.delete(environment.baseUrl + APIROUTES.GLOBALPRAYER.JOURNAL.DELETE + journal).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}
