import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIROUTES } from '../../constants';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class EmpowerService {

  constructor(
    private baseService: BaseService
  ) { }

  getAllRoles() {
    return this.baseService.get(environment.baseUrl + APIROUTES.EMPOWERMENT.ROLES).pipe(catchError(this.errorHandler));
  }

  getRoles() {
    return this.baseService.get(environment.baseUrl + APIROUTES.EMPOWERMENT.FILTEREDROLES).pipe(catchError(this.errorHandler));
  }

  searchUsers(name: string) {
    return this.baseService.post(environment.baseUrl + APIROUTES.EMPOWERMENT.SEARCHUSER, {name}).pipe(catchError(this.errorHandler));
  }

  getRoleByPermission(permission: number) {
    return this.baseService.get(environment.baseUrl + APIROUTES.EMPOWERMENT.ROLEBYPERMISSION(permission)).pipe(catchError(this.errorHandler));
  }

  getPermissions() {
    return this.baseService.get(environment.baseUrl + APIROUTES.EMPOWERMENT.PERMISSIONS).pipe(catchError(this.errorHandler));
  }

  getPermissionByRole(role: number) {
    return this.baseService.get(environment.baseUrl + APIROUTES.EMPOWERMENT.PERMISSIONSBYROLE(role)).pipe(catchError(this.errorHandler));
  }

  giveUserRole(user_id: number, role_id: number) {
    return this.baseService.post(environment.baseUrl + APIROUTES.EMPOWERMENT.USERROLE, {user_id, role_id}).pipe(catchError(this.errorHandler));
  }

  revokeUserRole(user_id: number, role_id: number) {
    return this.baseService.deleteWithBody(environment.baseUrl + APIROUTES.EMPOWERMENT.USERROLE, {user_id, role_id}).pipe(catchError(this.errorHandler));
  }

  giveUserPermission(user_id: number, permission_id: number) {
    return this.baseService.post(environment.baseUrl + APIROUTES.EMPOWERMENT.USERPERMISSION, {user_id, permission_id}).pipe(catchError(this.errorHandler));
  }

  revokeUserPermission(user_id: number, permission_id: number) {
    return this.baseService.deleteWithBody(environment.baseUrl + APIROUTES.EMPOWERMENT.USERPERMISSION, {user_id, permission_id}).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}
