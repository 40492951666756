import { Pipe, PipeTransform } from '@angular/core';
import { RolePermission } from '../constants';

@Pipe({
  standalone: true,
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {

  transform(value: number|string, permissions: RolePermission[] = []): boolean {
    if (!permissions.length) return false;

    return !!permissions.find((permission) => permission.name === value || permission.id === value);
  }

}
