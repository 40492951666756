import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../shared/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, public localStorage: StorageService){ }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(!this.localStorage.isAuthenticated()){
      this.router.navigateByUrl("/auth", {replaceUrl: true});
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(!this.localStorage.isAuthenticated()){
      this.router.navigateByUrl("/auth");
      return false;
    } else {
      const connectedUserRole = this.localStorage.user!.roles_list.map((role) => role.name).join(',');
      let url = state.url;
      url = url.startsWith('/') ? url.slice(1, url.length) : url;

      // For super admin, the connectedUserRole can not have multiple roles
      if(connectedUserRole.includes('superAdmin')) {
        return true;
      }
      if(url.startsWith('super-admin') && !connectedUserRole.includes('superAdmin')) {
        this.router.navigateByUrl("/tabs", {replaceUrl: true}); // update to /super-admin/dashboard
        return false;
      }

      return true;
    }
  }
}